.h-fscreen
{
    min-height: 100vh;
}

.center
{
    display: flex;
    align-items: center;
    justify-content: center;
}
.column
{
    flex-direction: column;
}
.secondaryColor
{
    color: var(--secondary-color);
}
.EmptyPadding
{
    height: 100px;
}
.primaryButton
{
    padding: 12px 20px ;
    border-left: 1px solid var(--dark-text);
    border-right: 1px solid transparent;
    font-size: 15px;
    position: relative;
    width: fit-content;
    display: inline-block;
    transition: 0.5s ease;
    cursor: pointer;
    color: var(--dark-text);
}
.primaryButton::before,.primaryButton::after
{
    width: 60%;
    height: 1px;
    position: absolute;
    content: "";
    border-radius: 5px;

    background-color: var(--dark-text);
    left: 0;
    -webkit-transition: 0.5s;
    transition: 0.5s;

}
.primaryButton::before
{
    top: 0;
}
.primaryButton::after
{
    bottom: 0;
}
.primaryButton:hover
{
    border-right-color: var(--dark-text);
    color: var(--secondary-color);
    
}
.primaryButton:hover::before,.primaryButton:hover::after
{
    width: 100% ;
    
}
.primaryButtonIcon
{
    margin-left: 20px;
    transition: 0.6s ease;
    filter: var(--logo-filter);
}

.primaryButtonIconBefore
{
   
    display: inline-block;
    position: absolute;
    right: 40px;
    width: 18px;
    height: 18px;
    background: transparent;
    border-radius: 50%;
    margin-top: 1px;
    transition: 0.5s ease;
}
.primaryButton:hover .primaryButtonIconBefore
{
    right: 40px;
    background: var(--secondary-color);
}
.primaryButton:hover .primaryButtonIcon
{
    transform: scaleY(-1);
    margin-top: 2px;
  
}


.headingPadding
{
    padding-left: 10vw;
}