.founderMessageWrapper
{
    display: flex;
    flex-wrap: wrap;
    background-color: var(--primary-color);
    min-height: 80vh;
    justify-content: center;
    padding:3% 0 ;
}

.founderImage
{
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.founderImage img
{
    width: 100%;
    height:100%;
    object-fit: cover;
}
#phoneImage
{
    display: none;
    width: 90%;
    height: auto;
    margin: 40px auto;
}

.founderPara
{
    color: var(--founder-gray);
    font-family: var(--secondary-font);
    font-size: 2.4vh;
    letter-spacing: 1px;
    margin: 3% 0;
}
.founderSignature
{
    margin-top: 4%;
    width: 70%;
}

.founderSignature>img
{
    filter: var(--signature-filter);
    height: 100px;
    
}
.designationDiv
{
    margin-top: 12px;
}

.name
{
    color: var(--dark-text);
    font-size: 3vh;
}
.designation
{
    font-size: 3vh;
    color: var(--founder-gray);
    font-family: 'Bellefair', serif;
}

@media screen and (max-width:950px) {
    #phoneImage
    {
        display: block;
    }
    .founderImage
    {
        display: none;
    }
}