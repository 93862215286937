.AlternateLanding
{
    height: 50vh;
    background-repeat: no-repeat !important;
    background-color: rgba(17, 17, 17, 0.308);
    background-blend-mode: darken !important;
    background-size: cover !important;
}
.alternateLandingTitle
{
    font-size: 7vh;
    font-weight: 600;
    color: #fff;
}