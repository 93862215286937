
.TeamWrapper{
    background: var(--primary-color);
    padding: 10vh 0;
}
.teamCardWrapper
{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
   
}
.teamCard
{
    
    padding: 0 !important;
    margin: 20px;
    padding: 40px 30px;
    
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
   
}

.teamImage
{
    width: 100%;
    height: 250px;
    position: relative;
    overflow: hidden;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

}
.teamImage>img
{
    width: 100%;
    height:100%;
    object-fit: cover;
   
    /* filter: brightness(80%); */
}

.CardDetails
{   padding: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px -1px 4px -2px var(--white-text) inset;
    background: var(--dark-text);
    border: 1px solid #e7e7e7;
    border-top: transparent ;
    border-radius: 100px 0px 623px 77px / 0px 50px 128px 58px;
}
.teamMemberName
{
    color: var(--white-text);
    font-size: 28px;
}
.teamCardDesignation
{
    color: var(--secondary-color);

}
.teamCardpara
{
    color: var(--white-text);
    width: 80%;
    font-size: 12px;
    text-align: center;
    letter-spacing: 0.5px;
    padding-bottom: 40px;
}
