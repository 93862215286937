.AboutSection
{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background: var(--primary-color);
    justify-content: center;
    padding: 0;
}
.AboutSection:nth-of-type(1)
{
    padding: 10vh 1vw;
}

.aboutImage>img
{
    width: 100%;
    height: auto;
}
.aboutDetails>h5
{
    font-size: 21px;
    margin: 32px 0;
    color: rgb(156 150 150);
}
.aboutHeadings
{
    font-family: 'Kaushan Script', cursive;
    font-size: 5vh;
    color: var(--dark-text);
    margin:3% 0;
}
.aboutsecondary
{
    font-weight: 600;
    letter-spacing: 1px;
}
.aboutPara
{
    font-size: 21px;
    color: rgb(122, 120, 120);
    color: var(--about-gray);
    font-family: 'Bellefair', serif;
    

}
@media screen and (max-width:1000px){
    
    #spiceImg
    {
    display: none;       
    }
}
    

.AboutPage
{
    background: url('../../images/DSC_5892.JPG');
    background-position: center;
}