#lightDarkToggler
{
    color: var(--dark-text) !important;
    
}
#lightDarkToggler>i
{
    font-size: 4vh !important;
   
}
#lightDarkToggler>img
{
    width: 40px;
    height: auto;
}
.moon
{
    width: 30px !important;
}