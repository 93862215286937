.gallerySection
{
background: var(--primary-color);
}
.gallery-image {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: baseline;
  }
  
  .gallery-image img {
    min-height: 250px;
    width: 100%;
    transform: scale(1.1);
    transition: all 0.4s ease;
  }
  
  .img-box {
    box-sizing: content-box;
    margin: 10px;
    min-height: 250px;
    /* width: 30%; */
    overflow: hidden;
    display: inline-block;
    color: white;
    position: relative;
    background-color: white;
    box-shadow: var(--box-shadow);
    position: relative;
  }
  
  .caption {
    position: absolute;
    bottom: 5px;
    left: 20px;
    opacity: 0.0;
    width: max-content;
    transition: transform 0.3s ease, opacity 0.3s ease;
 
  }
  .caption>p
  {
      font-size: 3vh;
  }
  
  .transparent-box {
    height: 100%;
    width: fit-content;
    background: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgba(19, 19, 19, 0.685) 90%,transparent 100%) !important;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: background-color 0.3s ease;
    opacity: 1;
  }
  
  .img-box:hover img { 
    transform: scale(1.2);
    filter: brightness(0.6);
  }
  
  .img-box:hover .transparent-box {
    background-color:rgba(0, 0, 0, 0.733);
  }
  
  .img-box:hover .caption {
    transform: translateY(-20px);
    opacity: 1.0;
  }
  
  .img-box:hover {
    cursor: pointer;
  }
  
  .caption > p:nth-child(2) {
    font-size: 0.8em;
  }
  
  .opacity-low {
    opacity: 0.5;
  }


  .galleryPage
  {
      background: url('../../images/DSC_9697-Edit.jpg') rgba(19, 19, 19, 0.466);
      background-blend-mode: darken;
      background-position: center;
  }