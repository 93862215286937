
.MenuWrapper{
    background: var(--primary-color);
    padding: 10vh 0;
}
.menuCardWrapper
{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
   
}
.menuCard
{
    
    padding: 0 !important;
    margin: 20px;
    min-height: 400px;
    padding: 40px 30px;
    position: relative;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    overflow: hidden;
  
}

.menuImage
{
    width: 100%;
    min-height: 400px;
    height: 100%;
    position: relative;
    overflow: hidden;
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
    border-radius: 100px 0px 623px 77px / 0px 50px 128px 58px;
    filter:drop-shadow(var(--box-shadow));
    backdrop-filter: blur(3px);
}
.menuImage>img
{
    width: 100%;
    height:100%;
    object-fit: cover;
   transform: 0.5s ease;
    /* filter: brightness(80%); */
}
.menuCard:hover .menuImage>img
{
    transform: scale(1.1);
}
.menuCard:hover .menuCardDetails
{
    background: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgba(19, 19, 19, 0.849) 80%,transparent 100%) !important;
    transition: 0.5s ease;
}
.menuCardDetails
{
    
    padding: 10px 0;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 30%;
    display: flex;    
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px -1px 4px -2px var(--white-text) inset;
    /* background: var(--dark-text); */
    background: rgb(213,43,160);
    background: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgba(19, 19, 19, 0.685) 80%,transparent 100%) !important;
    border: 1px solid transparent !important;
    border-top: transparent  !important;
    transition: 0.5s ease;
}
.menuRecipeName
{
    color: #fff;
    font-size: 28px;
    text-align: center;
}
.menuRecipeServing
{
    color: rgb(238, 48, 86);
    font-size: 25px;

}
.menuCardpara
{
    color: #fff;
    width: 80%;
    font-size: 12px;
    text-align: center;
    letter-spacing: 0.5px;
    padding-bottom: 40px;
}
.menuCardPrice
{
    font-size: 31px;
}
.typographyImage
{
    width: 50px;
    height: auto;
    position: absolute;
    top: 2%;
    left: 4%;
}
.MenuPage
{
    background: url('../../images/DSC_0138-Edit.jpg');
    background-position: center;
}