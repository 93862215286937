.smallText
{
    color: var(--dark-text);
font-size: 18px;
}
.bigText
{
    font-family: var(--secondary-font);
    color:var(--dark-text);
    font-weight: 800;
    font-size: 40px;
}