.landingSection
{
    background: url('../../images/landing.jpeg');
    background-color: var(--primary-color);
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
    justify-content: flex-start;
    padding-left: 10%;
    color: var(--dark-text);   
}

@keyframes blink {
    0%
    {
        /* opacity: 1; */
       filter: blur(0);
    }
    50%
    {
        /* opacity: 0; */
       filter: blur(14px);
    }
    100%
    {
        /* opacity: 1; */
       filter: blur(0);
    }
    
}
.landingDetails
{
    width: 50%;
}
.landingTitle
{
    font-size: 24px;
    font-family: var(--primary-font);
    font-style: italic;
    font-weight: 600;
}
.landingBtnWrapper
{
    justify-content: flex-start;
    flex-wrap: wrap;
}
.landingHeading
{
    font-size: 6vh;
    font-family: var(--secondary-font);
    font-weight: 800;
    color: var(--dark-text);
}
.landingPara
{
    font-size: 16px;
    margin: 12px 0;
}
.landingButton
{
    margin-top: 20px; color: var(--dark-text);
}
.landingvideoBtn
{
    padding: 12px 20px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    margin-top: 14px;
    transition: 0.5s ease;
    cursor: pointer;
    color: var(--dark-text);
}
#playBtn
{
    width: 45px;
    height: 45px;
    background: rgb(235, 232, 232);
    display: grid;
    place-items: center;
    border-radius: 50%;
    padding: 12px;
    padding-left: 15px;
    margin-right: 13px;
    transition: 0.5s ease;
}
.landingvideoBtn:hover
{
    color: var(--secondary-color);
}
.landingvideoBtn:hover #playBtn
{
    background: #555;
}

@media screen and (max-width:800px) {
    .landingDetails
    {
        width: 90%;
    }

    .landingSection
    {
        background: url('../../images/DSC_0073-Edit.jpg');
    background-size: cover;
    background-color: rgba(0, 0, 0, 0.637);
    background-blend-mode:darken;
    background-position: center;
    }
    .landingvideoBtn
    {
        margin-left: 0;
        padding-left: 0 !important;
        align-items: center;
        justify-content: flex-start;
    }
    .landingHeading,.landingDetails,.landingButton,.landingPara,.landingvideoBtn
    {
        color: #fff;
    }
    .landingButton
    {
        border-color:#fff ;
        border-right: var(--white-text);
    }
    .landingButton::before,.landingButton::after
    {
        background-color: #fff;
    }
    #landingBtnIcon
    {
        filter: brightness(500%);
    }
}

@media screen and (max-width:480px) {
    .landingSection
    {
        min-height: 100vh;
    }
    .landingDetails
    {
        padding-top: 20vh;
    }
}