@import url(https://fonts.googleapis.com/css2?family=Cardo:ital,wght@0,400;0,700;1,400&family=Lora:ital,wght@0,400;0,700;1,400;1,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bellefair&family=Kaushan+Script&family=Kings&display=swap);

.h-fscreen
{
    min-height: 100vh;
}

.center
{
    display: flex;
    align-items: center;
    justify-content: center;
}
.column
{
    flex-direction: column;
}
.secondaryColor
{
    color: var(--secondary-color);
}
.EmptyPadding
{
    height: 100px;
}
.primaryButton
{
    padding: 12px 20px ;
    border-left: 1px solid var(--dark-text);
    border-right: 1px solid transparent;
    font-size: 15px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: inline-block;
    transition: 0.5s ease;
    cursor: pointer;
    color: var(--dark-text);
}
.primaryButton::before,.primaryButton::after
{
    width: 60%;
    height: 1px;
    position: absolute;
    content: "";
    border-radius: 5px;

    background-color: var(--dark-text);
    left: 0;
    transition: 0.5s;

}
.primaryButton::before
{
    top: 0;
}
.primaryButton::after
{
    bottom: 0;
}
.primaryButton:hover
{
    border-right-color: var(--dark-text);
    color: var(--secondary-color);
    
}
.primaryButton:hover::before,.primaryButton:hover::after
{
    width: 100% ;
    
}
.primaryButtonIcon
{
    margin-left: 20px;
    transition: 0.6s ease;
    -webkit-filter: var(--logo-filter);
            filter: var(--logo-filter);
}

.primaryButtonIconBefore
{
   
    display: inline-block;
    position: absolute;
    right: 40px;
    width: 18px;
    height: 18px;
    background: transparent;
    border-radius: 50%;
    margin-top: 1px;
    transition: 0.5s ease;
}
.primaryButton:hover .primaryButtonIconBefore
{
    right: 40px;
    background: var(--secondary-color);
}
.primaryButton:hover .primaryButtonIcon
{
    transform: scaleY(-1);
    margin-top: 2px;
  
}


.headingPadding
{
    padding-left: 10vw;
}
:root
{
  --primary-color:#fff;
  /* --secondary-color:#ff2626; */
  --secondary-color:#FF0303;
  --white-text: #fff;
  --dark-text:#111;
  --dark-gray-text:#111;
  --primary-font:'Lora',cursive;
  --secondary-font:'Cardo',serif;
  --logo-filter:brightness(100%);
  --signature-filter:brightness(100%);
  --box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
  --contact-icon:brightness(100%);
  --about-gray:rgb(197, 194, 194);
  --founder-gray:#333;
}

*
{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Lora',cursive;
  font-family: var(--primary-font);
  transition: 0.4s ease;
}
html
{
  scroll-behavior: smooth;
}
a,a:hover,a:focus,a:active
{
  text-decoration: none !important; 
  color: #fff;
}
body {
  margin: 0;  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100vw;
  overflow-x: hidden;
  
}
.row
{
  margin: 0;
  padding: 0;
}

.Nav
{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 2%;
    background: transparent;
    width: 100%;
    height: auto;
    min-height: 100px;
    position: fixed;
    z-index:99;
    transition: 0.5s ease !important;
    color: #111;
}

.navLogo
{
    width: 150px;
    height: 100%;
}
.navLogo>img
{
    width: 100%;
    height: 100%;
    image-rendering: crisp-edges;
}
.navMenu
{
    display: flex;
    margin-left: 10%;
}
.menuItem
{
        margin: 0 12px;
        font-size: 18px;
        color: var(--dark-text);
}

.menuItem:hover
{
    color: var(--secondary-color);
}
.active
{
    color: var(--secondary-color);
}
.hamBurgerMenu
{
    display: none;
    position: relative;    
    flex-direction: column;
    width: auto;
    height: 100%;
    padding: 12px ;
    cursor: pointer;
    margin-right: 3%;
}
#closeBtn
{
    display: none;
}
.hamBurgerMenu>span
{
    width: 25px;
    height: 3px;
    background:var(--dark-text) ;
    margin: 3px 0;
    border-radius: 50px;
    transition: 0.5s ease;
}
.hamBurgerMenu>span:nth-of-type(2)
{
    width: 32px;
}
.hamBurgerMenu>span:nth-of-type(3)
{
    width: 28px;
    transition-delay: 0.1s;
}

.hamBurgerMenu:hover #_1,.hamBurgerMenu:hover #_3
{
    width: 32px;
}
.rightMenu
{
    display: flex;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    align-items: center;
    justify-content: center;
    padding: 0 4%;
}
@media screen and (max-width:800px) {
    .Nav
    {
        position: fixed;
     justify-content: space-between;
     background: var(--primary-color) !important;
    }
    .hamBurgerMenu,#closeBtn
    {
        display: flex;
    }
    #closeBtn
    {
        position: absolute;
        top: 2%;
        left: 12%;
        font-size: 6vh;
    }
    .navMenu
    {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: -20%;
        top: 0;
        position: fixed !important;
        width: 0vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.911);
        color: #fff;
        transition: .5s ease;
    }
    .menuItem
    {
        color: #fff;
        font-size: 4vh;
        margin: 5% 0;
    }
    .open
    {
        width: 100vw;
        right: 0;
        transition: .5s ease;
        z-index: 99;
    }   
}

#lightDarkToggler
{
    color: var(--dark-text) !important;
    
}
#lightDarkToggler>i
{
    font-size: 4vh !important;
   
}
#lightDarkToggler>img
{
    width: 40px;
    height: auto;
}
.moon
{
    width: 30px !important;
}
.smallText
{
    color: var(--dark-text);
font-size: 18px;
}
.bigText
{
    font-family: var(--secondary-font);
    color:var(--dark-text);
    font-weight: 800;
    font-size: 40px;
}
.AboutSection
{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background: var(--primary-color);
    justify-content: center;
    padding: 0;
}
.AboutSection:nth-of-type(1)
{
    padding: 10vh 1vw;
}

.aboutImage>img
{
    width: 100%;
    height: auto;
}
.aboutDetails>h5
{
    font-size: 21px;
    margin: 32px 0;
    color: rgb(156 150 150);
}
.aboutHeadings
{
    font-family: 'Kaushan Script', cursive;
    font-size: 5vh;
    color: var(--dark-text);
    margin:3% 0;
}
.aboutsecondary
{
    font-weight: 600;
    letter-spacing: 1px;
}
.aboutPara
{
    font-size: 21px;
    color: rgb(122, 120, 120);
    color: var(--about-gray);
    font-family: 'Bellefair', serif;
    

}
@media screen and (max-width:1000px){
    
    #spiceImg
    {
    display: none;       
    }
}
    

.AboutPage
{
    background: url(/static/media/DSC_5892.e7a6a911.JPG);
    background-position: center;
}
.landingSection
{
    background: url(/static/media/landing.9e38578b.jpeg);
    background-color: var(--primary-color);
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
    justify-content: flex-start;
    padding-left: 10%;
    color: var(--dark-text);   
}

@keyframes blink {
    0%
    {
        /* opacity: 1; */
       -webkit-filter: blur(0);
               filter: blur(0);
    }
    50%
    {
        /* opacity: 0; */
       -webkit-filter: blur(14px);
               filter: blur(14px);
    }
    100%
    {
        /* opacity: 1; */
       -webkit-filter: blur(0);
               filter: blur(0);
    }
    
}
.landingDetails
{
    width: 50%;
}
.landingTitle
{
    font-size: 24px;
    font-family: var(--primary-font);
    font-style: italic;
    font-weight: 600;
}
.landingBtnWrapper
{
    justify-content: flex-start;
    flex-wrap: wrap;
}
.landingHeading
{
    font-size: 6vh;
    font-family: var(--secondary-font);
    font-weight: 800;
    color: var(--dark-text);
}
.landingPara
{
    font-size: 16px;
    margin: 12px 0;
}
.landingButton
{
    margin-top: 20px; color: var(--dark-text);
}
.landingvideoBtn
{
    padding: 12px 20px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    margin-top: 14px;
    transition: 0.5s ease;
    cursor: pointer;
    color: var(--dark-text);
}
#playBtn
{
    width: 45px;
    height: 45px;
    background: rgb(235, 232, 232);
    display: grid;
    place-items: center;
    border-radius: 50%;
    padding: 12px;
    padding-left: 15px;
    margin-right: 13px;
    transition: 0.5s ease;
}
.landingvideoBtn:hover
{
    color: var(--secondary-color);
}
.landingvideoBtn:hover #playBtn
{
    background: #555;
}

@media screen and (max-width:800px) {
    .landingDetails
    {
        width: 90%;
    }

    .landingSection
    {
        background: url(/static/media/DSC_0073-Edit.f4db189e.jpg);
    background-size: cover;
    background-color: rgba(0, 0, 0, 0.637);
    background-blend-mode:darken;
    background-position: center;
    }
    .landingvideoBtn
    {
        margin-left: 0;
        padding-left: 0 !important;
        align-items: center;
        justify-content: flex-start;
    }
    .landingHeading,.landingDetails,.landingButton,.landingPara,.landingvideoBtn
    {
        color: #fff;
    }
    .landingButton
    {
        border-color:#fff ;
        border-right: var(--white-text);
    }
    .landingButton::before,.landingButton::after
    {
        background-color: #fff;
    }
    #landingBtnIcon
    {
        -webkit-filter: brightness(500%);
                filter: brightness(500%);
    }
}

@media screen and (max-width:480px) {
    .landingSection
    {
        min-height: 100vh;
    }
    .landingDetails
    {
        padding-top: 20vh;
    }
}

.MenuWrapper{
    background: var(--primary-color);
    padding: 10vh 0;
}
.menuCardWrapper
{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
   
}
.menuCard
{
    
    padding: 0 !important;
    margin: 20px;
    min-height: 400px;
    padding: 40px 30px;
    position: relative;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transition: 0.5s;
    overflow: hidden;
  
}

.menuImage
{
    width: 100%;
    min-height: 400px;
    height: 100%;
    position: relative;
    overflow: hidden;
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
    border-radius: 100px 0px 623px 77px / 0px 50px 128px 58px;
    -webkit-filter:drop-shadow(var(--box-shadow));
            filter:drop-shadow(var(--box-shadow));
    -webkit-backdrop-filter: blur(3px);
            backdrop-filter: blur(3px);
}
.menuImage>img
{
    width: 100%;
    height:100%;
    object-fit: cover;
   transform: 0.5s ease;
    /* filter: brightness(80%); */
}
.menuCard:hover .menuImage>img
{
    transform: scale(1.1);
}
.menuCard:hover .menuCardDetails
{
    background: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgba(19, 19, 19, 0.849) 80%,transparent 100%) !important;
    transition: 0.5s ease;
}
.menuCardDetails
{
    
    padding: 10px 0;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 30%;
    display: flex;    
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px -1px 4px -2px var(--white-text) inset;
    /* background: var(--dark-text); */
    background: rgb(213,43,160);
    background: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgba(19, 19, 19, 0.685) 80%,transparent 100%) !important;
    border: 1px solid transparent !important;
    border-top: transparent  !important;
    transition: 0.5s ease;
}
.menuRecipeName
{
    color: #fff;
    font-size: 28px;
    text-align: center;
}
.menuRecipeServing
{
    color: rgb(238, 48, 86);
    font-size: 25px;

}
.menuCardpara
{
    color: #fff;
    width: 80%;
    font-size: 12px;
    text-align: center;
    letter-spacing: 0.5px;
    padding-bottom: 40px;
}
.menuCardPrice
{
    font-size: 31px;
}
.typographyImage
{
    width: 50px;
    height: auto;
    position: absolute;
    top: 2%;
    left: 4%;
}
.MenuPage
{
    background: url(/static/media/DSC_0138-Edit.49bf685d.jpg);
    background-position: center;
}
.founderMessageWrapper
{
    display: flex;
    flex-wrap: wrap;
    background-color: var(--primary-color);
    min-height: 80vh;
    justify-content: center;
    padding:3% 0 ;
}

.founderImage
{
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.founderImage img
{
    width: 100%;
    height:100%;
    object-fit: cover;
}
#phoneImage
{
    display: none;
    width: 90%;
    height: auto;
    margin: 40px auto;
}

.founderPara
{
    color: var(--founder-gray);
    font-family: var(--secondary-font);
    font-size: 2.4vh;
    letter-spacing: 1px;
    margin: 3% 0;
}
.founderSignature
{
    margin-top: 4%;
    width: 70%;
}

.founderSignature>img
{
    -webkit-filter: var(--signature-filter);
            filter: var(--signature-filter);
    height: 100px;
    
}
.designationDiv
{
    margin-top: 12px;
}

.name
{
    color: var(--dark-text);
    font-size: 3vh;
}
.designation
{
    font-size: 3vh;
    color: var(--founder-gray);
    font-family: 'Bellefair', serif;
}

@media screen and (max-width:950px) {
    #phoneImage
    {
        display: block;
    }
    .founderImage
    {
        display: none;
    }
}

.TeamWrapper{
    background: var(--primary-color);
    padding: 10vh 0;
}
.teamCardWrapper
{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
   
}
.teamCard
{
    
    padding: 0 !important;
    margin: 20px;
    padding: 40px 30px;
    
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
    transition: 0.5s;
   
}

.teamImage
{
    width: 100%;
    height: 250px;
    position: relative;
    overflow: hidden;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

}
.teamImage>img
{
    width: 100%;
    height:100%;
    object-fit: cover;
   
    /* filter: brightness(80%); */
}

.CardDetails
{   padding: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px -1px 4px -2px var(--white-text) inset;
    background: var(--dark-text);
    border: 1px solid #e7e7e7;
    border-top: transparent ;
    border-radius: 100px 0px 623px 77px / 0px 50px 128px 58px;
}
.teamMemberName
{
    color: var(--white-text);
    font-size: 28px;
}
.teamCardDesignation
{
    color: var(--secondary-color);

}
.teamCardpara
{
    color: var(--white-text);
    width: 80%;
    font-size: 12px;
    text-align: center;
    letter-spacing: 0.5px;
    padding-bottom: 40px;
}

.videoWrapper
{
    background: url(/static/media/DSC_0138-Edit.49bf685d.jpg);
    background-attachment: fixed;
    background-position: center;
    padding: 5vh 0;
    min-height: 50vh;
}
#videoWrapperTitle
{
    font-size: 7vh;
    font-family: var(--secondary-font);
    font-weight: 800;
    color: #fff;
    margin-bottom: 30px;
    text-align: center;
}

.button {
    display: inline-block;
    position: relative;
  }
  .button.is-play {
    background-color: rgba(255, 255, 255, 0.425);
    border-radius: 50%;
    width: 100px;
    height: 100px;
  }
  .button.is-play .button-outer-circle {
    background: rgba(255, 255, 255, 0.301);
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
  }
  .button.is-play .button-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    transform: translate(-50%, -50%);
  }
  .button.is-play .button-icon .triangle {
    animation: fadeIn 7s ease;
  }
  .button.is-play .button-icon .path {
    stroke-dasharray: 90;
    stroke-dashoffset: 0;
    animation: triangleStroke 3s;
    animation-timing-function: ease;
  }
  .has-scale-animation {
    animation: smallScale 3s infinite;
  }
  .has-delay-short {
    animation-delay: 0.5s;
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes triangleStroke {
    from {
      stroke-dashoffset: 90;
    }
    to {
      stroke-dashoffset: 0;
    }
  }
  @keyframes smallScale {
    from {
      transform: scale(1);
      opacity: 1;
    }
    to {
      transform: scale(1.5);
      opacity: 0;
    }
  }
  
.AlternateLanding
{
    height: 50vh;
    background-repeat: no-repeat !important;
    background-color: rgba(17, 17, 17, 0.308);
    background-blend-mode: darken !important;
    background-size: cover !important;
}
.alternateLandingTitle
{
    font-size: 7vh;
    font-weight: 600;
    color: #fff;
}

.Footer,.copyright
{
    padding: 10vh 5vw;
    width: 100vw;
    background: #111;
    /* position: fixed; */
    bottom: 0;
    left: 0;
    
}
.footerLogo
{
    width: 100px;
    height: auto;
}
.footerSocials
{
    display: flex;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
 
    padding: 12px 20px;
}
.FooterTopBar
{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
} 
@media screen and (max-width:480px)
{
    .FooterTopBar
    {   flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }
    .footerSocials
    {
        margin-top: 20px;
        padding: 0;
    }
    .FooterContent
    {
        display: block;
    }
  

}
.FooterTopBar::after
{
    content: '';
    position: relative;
    width: 90%;
    margin: 30px auto;
    height: 1px;
    background: rgba(238, 238, 238, 0.089);
}
.footerSocials
{
    display: flex;
    align-items: center;
    
}
.footerSocials>span
{
    margin-right: 10px;color: #fff;
}
.footerSocials>a
{
    border: 1px solid #eee;
    padding: 3px 8px;
    font-size: 17px;
    color: var(--secondary-color);
   transition: 0.4s ease;
}
.footerSocials>a:nth-of-type(2)
{
    border-left: transparent;
    border-right: transparent;
}
.footerSocials>a:nth-of-type(3)
{
    border-right: transparent;
}
.footerSocials>a:hover
{
    background: #eee;
}
.FooterContent
{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
}
.FooterContent>div

{
    margin-top: 40px;
    /* width: 100%; */
}
.FooterConentTitle
{
    color: var(--secondary-color);
    margin-bottom: 20px;
}

.FooterContentPara
{
    color: rgb(218, 214, 214);
    font-size: 14px;
    
}
.FooterContact
{
    color: rgb(218, 214, 214);
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    
}
.FooterContact>span
{
text-align: left;
/* margin-right: 10px; */
width: -webkit-max-content;
width: -moz-max-content;
width: max-content;
}
.FooterContact a
{
    text-decoration: none;
    color: rgb(218, 214, 214) ;
    transition: 0.3s ease;
    text-align: left;

}
.FooterContact a:hover
{
    color: var(--secondary-color);
}
.footerAddress
{
    align-items: flex-start;
}
#footerAddress
{
    text-align: right;
}
#newsletterForm
{
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    margin-top: 12px;
}

#newsletterForm>input
{
background-color: #292929;
border: 1px solid rgba(238, 238, 238, 0.192);
width: 60%;
padding: 20px;
color: var(--white-text);
transition: 0.5s ease;
}
#newsletterForm>input:focus
{
    outline: 0;
    background: rgba(238, 238, 238, 0.233);
}
#newsletterForm>input,#newsletterForm>button
{
    height: 50px;
  
}
#newsletterForm>button
{
    background: var(--secondary-color);
    color: #fff;
    width: 30%;
    margin-left: 12px;
    transition: 0.4s ease;
    outline: 0;
    border: 1px solid var(--secondary-color);
    font-weight: 500;
    font-size: 18;
    letter-spacing: 1px;
}

#newsletterForm>button:hover
{
    background: #fff;
    color: var(--primary-color);
}
.copyright
{
    
    padding: 10px 0 ;
    color: var(--dark-text);
    background: var(--primary-color);
}

@media screen and (max-width:1000px){
    .FooterContent>div
{
 
    width: 90%;
    margin: 40px auto;
}
}
.contactPage
{
    background: url(/static/media/DSC_0084.5d832d08.jpg);
    background-position: center;
}
.contactSection
{
    background: var(--primary-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
}
.mapContainer
{
    width: 100vw;
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.mapContainer>iframe
{
    width: 80%;
    height: 90%;
    -webkit-filter: brightness(0.6);
            filter: brightness(0.6);
    transition: 0.5s ease;
}
.mapContainer>iframe:hover{
    -webkit-filter: brightness(0.8);
            filter: brightness(0.8);
}
.contactDetailsWrapper
{
    width: 80vw;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 70vh;
    flex-wrap: wrap;

}

.contactForm
{
    margin: 2% auto;
    width: 100%;
    padding: 0 !important;
}
input[type=text].contactForm::-webkit-input-placeholder
{
    color: var(--white-text);
}
input[type=text].contactForm:-ms-input-placeholder
{
    color: var(--white-text);
}
input[type=text].contactForm::placeholder
{
    color: var(--white-text);
}
.contactForm label,.contactHeading
{
    color: var(--dark-text);
}
.contactHeading
{
    font-size: 5vh;
}
.form-group
{
    display: flex;
    flex-direction: column;
}
.contactInput
{
    padding: 2% ;
    border-radius: 4px;
    border: 1px solid var(--dark-text);
    color: var(--white-text);
    outline: none;
    background: var(--dark-text);
    min-height: 50px;
}
.contactForm textarea
{
    height: 100px;
}
.contactDetails
{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    padding: 0 !important;   
    
}
.contactDetailDiv
{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5% 0;
    position: relative;
    /* margin-left: 3%; */
}
.contactDetailDiv>img
{
    width: 50px;
    height: 50px;
    -webkit-filter: var(--contact-icon);
            filter: var(--contact-icon);
    /* margin-right: 1%; */
}
.contactInfoDiv
{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    
    /* padding-left: 2%; */
    transform: translateX(12px);
}

.contactInfoDiv>span
{
    font-size: 130%;
    color: rgb(135,135,135);font-weight: 600;
}
.contactInfoDiv>span:nth-of-type(1)
{
    color: var(--dark-text);
    font-weight: 400;
}
.submitBtn
{
    background: var(--secondary-color);
    padding: 4% 2%;
    color: #fff;
    font-family:'Poppins',sans-serif;
    font-size: 130%;
    outline: none;
    border: none;
}

@media screen and (max-width:480px) {
    .contactDetailDiv
    {
        margin-left: -10%;
    }
   .contactDetailsWrapper
   {
       width: 90vw;
   } 
}
.gallerySection
{
background: var(--primary-color);
}
.gallery-image {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: baseline;
  }
  
  .gallery-image img {
    min-height: 250px;
    width: 100%;
    transform: scale(1.1);
    transition: all 0.4s ease;
  }
  
  .img-box {
    box-sizing: content-box;
    margin: 10px;
    min-height: 250px;
    /* width: 30%; */
    overflow: hidden;
    display: inline-block;
    color: white;
    position: relative;
    background-color: white;
    box-shadow: var(--box-shadow);
    position: relative;
  }
  
  .caption {
    position: absolute;
    bottom: 5px;
    left: 20px;
    opacity: 0.0;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    transition: transform 0.3s ease, opacity 0.3s ease;
 
  }
  .caption>p
  {
      font-size: 3vh;
  }
  
  .transparent-box {
    height: 100%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgba(19, 19, 19, 0.685) 90%,transparent 100%) !important;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: background-color 0.3s ease;
    opacity: 1;
  }
  
  .img-box:hover img { 
    transform: scale(1.2);
    -webkit-filter: brightness(0.6);
            filter: brightness(0.6);
  }
  
  .img-box:hover .transparent-box {
    background-color:rgba(0, 0, 0, 0.733);
  }
  
  .img-box:hover .caption {
    transform: translateY(-20px);
    opacity: 1.0;
  }
  
  .img-box:hover {
    cursor: pointer;
  }
  
  .caption > p:nth-child(2) {
    font-size: 0.8em;
  }
  
  .opacity-low {
    opacity: 0.5;
  }


  .galleryPage
  {
      background: url(/static/media/DSC_9697-Edit.697aaa8a.jpg) rgba(19, 19, 19, 0.466);
      background-blend-mode: darken;
      background-position: center;
  }
