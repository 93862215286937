.Nav
{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 2%;
    background: transparent;
    width: 100%;
    height: auto;
    min-height: 100px;
    position: fixed;
    z-index:99;
    transition: 0.5s ease !important;
    color: #111;
}

.navLogo
{
    width: 150px;
    height: 100%;
}
.navLogo>img
{
    width: 100%;
    height: 100%;
    image-rendering: crisp-edges;
}
.navMenu
{
    display: flex;
    margin-left: 10%;
}
.menuItem
{
        margin: 0 12px;
        font-size: 18px;
        color: var(--dark-text);
}

.menuItem:hover
{
    color: var(--secondary-color);
}
.active
{
    color: var(--secondary-color);
}
.hamBurgerMenu
{
    display: none;
    position: relative;    
    flex-direction: column;
    width: auto;
    height: 100%;
    padding: 12px ;
    cursor: pointer;
    margin-right: 3%;
}
#closeBtn
{
    display: none;
}
.hamBurgerMenu>span
{
    width: 25px;
    height: 3px;
    background:var(--dark-text) ;
    margin: 3px 0;
    border-radius: 50px;
    transition: 0.5s ease;
}
.hamBurgerMenu>span:nth-of-type(2)
{
    width: 32px;
}
.hamBurgerMenu>span:nth-of-type(3)
{
    width: 28px;
    transition-delay: 0.1s;
}

.hamBurgerMenu:hover #_1,.hamBurgerMenu:hover #_3
{
    width: 32px;
}
.rightMenu
{
    display: flex;
    width: max-content;
    align-items: center;
    justify-content: center;
    padding: 0 4%;
}
@media screen and (max-width:800px) {
    .Nav
    {
        position: fixed;
     justify-content: space-between;
     background: var(--primary-color) !important;
    }
    .hamBurgerMenu,#closeBtn
    {
        display: flex;
    }
    #closeBtn
    {
        position: absolute;
        top: 2%;
        left: 12%;
        font-size: 6vh;
    }
    .navMenu
    {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: -20%;
        top: 0;
        position: fixed !important;
        width: 0vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.911);
        color: #fff;
        transition: .5s ease;
    }
    .menuItem
    {
        color: #fff;
        font-size: 4vh;
        margin: 5% 0;
    }
    .open
    {
        width: 100vw;
        right: 0;
        transition: .5s ease;
        z-index: 99;
    }   
}
