@import url('./css/fonts.css');
@import url('./css/util.css');

:root
{
  --primary-color:#fff;
  /* --secondary-color:#ff2626; */
  --secondary-color:#FF0303;
  --white-text: #fff;
  --dark-text:#111;
  --dark-gray-text:#111;
  --primary-font:'Lora',cursive;
  --secondary-font:'Cardo',serif;
  --logo-filter:brightness(100%);
  --signature-filter:brightness(100%);
  --box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
  --contact-icon:brightness(100%);
  --about-gray:rgb(197, 194, 194);
  --founder-gray:#333;
}

*
{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--primary-font);
  transition: 0.4s ease;
}
html
{
  scroll-behavior: smooth;
}
a,a:hover,a:focus,a:active
{
  text-decoration: none !important; 
  color: #fff;
}
body {
  margin: 0;  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100vw;
  overflow-x: hidden;
  
}
.row
{
  margin: 0;
  padding: 0;
}