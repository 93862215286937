.contactPage
{
    background: url('../../images/DSC_0084.jpg');
    background-position: center;
}
.contactSection
{
    background: var(--primary-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
}
.mapContainer
{
    width: 100vw;
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.mapContainer>iframe
{
    width: 80%;
    height: 90%;
    filter: brightness(0.6);
    transition: 0.5s ease;
}
.mapContainer>iframe:hover{
    filter: brightness(0.8);
}
.contactDetailsWrapper
{
    width: 80vw;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 70vh;
    flex-wrap: wrap;

}

.contactForm
{
    margin: 2% auto;
    width: 100%;
    padding: 0 !important;
}
input[type=text].contactForm::placeholder
{
    color: var(--white-text);
}
.contactForm label,.contactHeading
{
    color: var(--dark-text);
}
.contactHeading
{
    font-size: 5vh;
}
.form-group
{
    display: flex;
    flex-direction: column;
}
.contactInput
{
    padding: 2% ;
    border-radius: 4px;
    border: 1px solid var(--dark-text);
    color: var(--white-text);
    outline: none;
    background: var(--dark-text);
    min-height: 50px;
}
.contactForm textarea
{
    height: 100px;
}
.contactDetails
{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    padding: 0 !important;   
    
}
.contactDetailDiv
{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5% 0;
    position: relative;
    /* margin-left: 3%; */
}
.contactDetailDiv>img
{
    width: 50px;
    height: 50px;
    filter: var(--contact-icon);
    /* margin-right: 1%; */
}
.contactInfoDiv
{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: max-content;
    
    /* padding-left: 2%; */
    transform: translateX(12px);
}

.contactInfoDiv>span
{
    font-size: 130%;
    color: rgb(135,135,135);font-weight: 600;
}
.contactInfoDiv>span:nth-of-type(1)
{
    color: var(--dark-text);
    font-weight: 400;
}
.submitBtn
{
    background: var(--secondary-color);
    padding: 4% 2%;
    color: #fff;
    font-family:'Poppins',sans-serif;
    font-size: 130%;
    outline: none;
    border: none;
}

@media screen and (max-width:480px) {
    .contactDetailDiv
    {
        margin-left: -10%;
    }
   .contactDetailsWrapper
   {
       width: 90vw;
   } 
}